@charset "utf-8";

body{
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  position: fixed;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.container-fluid{
  margin: 0px;
  padding: 0px;
}

.header{
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
}

.btn-custom-primary {
  background-color: rgb(237, 126, 1);
  color: white;
  border: none; 
  padding: 10px 20px; 
  border-radius: 5px; 
  cursor: pointer; 
}

.btn-custom-primary:hover {
  background-color: #ff9900; 
}
.btn-custom-primary:disabled {
  background-color: #ccc; 
  cursor: not-allowed; 
}

.save-button{
  text-align: center;
}

.alert-message{
  position: absolute;
  bottom: 0px;
  z-index: 9999;
}

.btn-custom-secondary {
  background-color: #333;
  color: white;
  border: none; 
  padding: 10px 20px; 
  border-radius: 5px; 
  cursor: pointer; 
}

.btn-custom-secondary:hover {
  background-color: #616060; 
}
.btn-custom-secondary:disabled {
  background-color: #ccc; 
  cursor: not-allowed; 
}

.show-progress{
  display: none;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.report-style-class{
  height: 100%;
  width: 100%;
  position: absolute;
}


.progress-container{
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 9999;
  position: absolute;
  display: flex;
  left:3%;
  backdrop-filter: blur(1px);
}

.progress-circle{
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin{
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.nav-item-container {
  position: relative;
}

.submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  white-space: nowrap; /* Ensure text doesn't wrap */
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 9999; /* Ensure it's above other content */
  list-style-type: none; 
  padding: 10px;
}

.nav-item-container:hover .submenu {
  display: block;
}

.arrow {
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s ease; /* Smooth transition for the arrow */
}

.nav-item-container:hover .arrow {
  transform: rotate(-90deg); /* Rotate arrow when submenu is visible */
}

.user-market-control{
  div{
    padding: 3px;
  }
}


@import
"login",
"sidebar",
"admin",
"dashboard",
"datatable",
"nmashome",
"events",
"contactus";
