.admin-page{
    .registration-text{
        background-color: #333;
        color: #fff;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .form-group{
        padding: 5px;
    }
    .password-not-match{
        border-color: red;
    }
}