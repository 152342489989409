.row>* {
    padding-left: revert-layer !important;
}

.nmas-home{
    padding: 0.5rem;
    font-size: 0.9rem;
    .message{
        height: 6vh;
        background-color: antiquewhite;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }
    .scroller{
        max-width: 740px;
        overflow: hidden;
    }
    .scroller__inner {
        padding-block: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    
    .image-scroll::-webkit-scrollbar {
        display: none;
    }
    // .scroller[data-animated="true"] {
    // overflow: hidden;
    // -webkit-mask: linear-gradient(
    //     90deg,
    //     transparent,
    //     white 20%,
    //     white 80%,
    //     transparent
    // );
    // mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    // }
    
    // .scroller[data-animated="true"] .scroller__inner {
    // width: max-content;
    // flex-wrap: nowrap;
    // animation: scroll var(--_animation-duration, 40s)
    //     var(--_animation-direction, forwards) linear infinite;
    // }
    
    // .scroller[data-direction="right"] {
    // --_animation-direction: reverse;
    // }
    
    // .scroller[data-direction="left"] {
    // --_animation-direction: forwards;
    // }
    
    // .scroller[data-speed="fast"] {
    // --_animation-duration: 20s;
    // }
    
    // .scroller[data-speed="slow"] {
    // --_animation-duration: 60s;
    // }
    
    // @keyframes scroll {
    // to {
    //     transform: translate(calc(-50% - 0.5rem));
    // }
    // }
    .slider-container {
        // width: 100%; /* Set the width of your slider container */
        // overflow: hidden; /* Hide overflowing content */
        // max-width: 500px;
        // margin: 0 auto;
        display: flex;
        overflow: hidden;
      }

      .slide{
       flex: 0 0 15%;
        transition: transform 0.5s ease-in-out;
      }

      .blank-row{
        padding: 10px;
      }

      .active{
        transform: translateX(-100%);
      }

      img {
        width: 100%;
        height: auto;
        // object-fit: cover;
        // display: block;
      }


      
      
      /* Additional styling as needed */
   
    .projects{

        ul li:first-child{
          margin-top: 5px !important;
        }
        ul{
          margin-bottom: 0px !important;
        }
        padding-top: 0.2rem;
        .nmas-header{
            background-color: #D9B8FF;
            text-align: center;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            height: 6vh;
        }
        .nmas-sub-header{
            border-radius: 10px;
            height: 5vh;
            // background: cadetblue;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            font-weight: 800;
            margin-bottom: 5px;
            width: max-content;
            span{
              margin-left: 15px;
            }
          
        }
        .nmas-event-header{
          border-radius: 10px;
          height: 5vh;
          // background: cadetblue;
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          margin-bottom: 5px;
          // width: max-content;
          span{
            margin-left: 15px;
          }
        
      }
        // .events{
        //   background-color: bisque;
        // }
        // .spcl-evnt{
        //   background-color: #D9B8FF;
        // }
        // .spcl-evnt-body{
        //   background-color: #D9B8FF;
        // }
        // .wkn-mon{
        //   background-color: #80CAFF;
        // }
        // .wkn-mon-body{
        //   background-color: #80CAFF;
        // }
    }
    .events{
        text-align: center;
        background-color: yellow;
    }
    .sevices{
        text-align: center;
        background-color: yellow;
    }
}



.container-nmass-home{
  // background-color: black;
  .nmass-status{
      height: 10rem;
      .status-div{
        margin: 1rem;
      }
  }
  .nmass-welcome{
    height: 10%;
  }
  .nmass-chart{
      height: 20rem;
  }
  .map-chart{
    background: white;
    margin-left: 14px;
  }
}

.container-nmass-home{
  padding: 10px;
  margin-right: -10px !important;
  height: calc(100% - 140px);
  .nmass-home{
    height: 100%;
  }
}

.namas-slideshow{
  grid-column: span 3;
  background-color: rgb(221 229 231 / 87%);
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
  }
}

.namas-sub-cmp {
  grid-column: span 3;
  background-color: #dddd;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  // text-align: center;
  padding: 10px;
  overflow: hidden;
  .slide-header{
    text-align: center;
    position: absolute;
    background-color: rgb(221 229 231 / 87%);
    height: 31px;
    /* z-index: 999; */
    width: -webkit-fill-available;
    color: black;
    font-weight: 500;
    opacity: 0.9;
    margin-top: 5px;

  }
  .slide-content{
    // position: absolute;
    text-align: center;
    max-height: 100%;
    min-height: 100%;
    // overflow: auto;
    // padding: 10px;
    img{
      max-height: 100%;
      max-width: 106%;
      margin: auto;
    }
    .nmass-slide-content{
      font-size: 14px;
    }
  }
}

.namas-component {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  height: 30%;
  gap: 10px;
}

.nsmass-welcome{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 10%;
  gap: 10px;
  margin-top: 10px;
}

.nmass-charts {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: calc(60% - 50px);
  gap: 10px;
  margin-top: 10px;
  .first-child {
    grid-column: span 3;
    border-radius: 5px;
  }
  .second-child{
    grid-column: span 3 !important;
    .bar-chart{
      height: 48%;
      border-radius: 5px;
      background-color: rgb(221 229 231 / 87%);
      box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.2);
    }
    .white-space{
      background-color: #fff;
      height: 10px;
    }
    .circulat-chart{
      height: calc(52% - 10px);
      border-radius: 5px;
      background-color: rgb(221 229 231 / 87%);
      box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.2);
    }
  }
}

.nmass-numbers {
  // width: 100%;
  // margin: 0px 30px;
  display: flow;
  
  .header-number{
    text-align: center;
    h1, h3{
      display: inline;
    }
  }
  // ul, li {
  //   margin: 0;
  //   padding: 0;
  // }
  .number{
    color: #ff8042;
    font-weight: 700;
   
  }
}







.status-div {
  background-color: #f0f8ff;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #b0c4de;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.status-div h2 {
  color: #333;
}

.status-badge {
  display: inline-block;
  padding: 8px;
  background-color: #5cb85c;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}
