.events-header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
h1 {
    margin: 0;
}
.content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.search-bar {
    padding: 1rem;
}
.table-container {
    margin-top: 1rem;
    overflow-x: auto;
    
}
table {
    width: 82%;
    border-collapse: collapse;
}
table, th, td {
    border: 1px solid #333; 
}
th, td {
    padding: 1rem;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    text-align: center;
}
.add-event-button {
    margin-top: 1rem;
}
.table-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.event-table{
    padding: 15px;
}

.markas-complete{
    cursor: pointer;
    margin-left: 15px;
}
.poc{
    cursor: pointer;
    margin-left: 0px;
}
.dashboard{
    cursor: pointer;
    margin-left: 15px;
}

.event-container{
    .market-dropdown{
        position: relative;
        left: 18px;
    }
    .event-search{
        margin-top: 22px;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0px;
    }
    .show-middle{
        text-align: end;
        align-items: center;
        justify-content: center;
        display: flex;
        span{
            margin-top: 20px;
        }
    }
    .search-icon{
        transform-origin: left;
        cursor: pointer;
        transform: translate(-30px, 8px);
    }

    .upload-button{
        margin-left: auto;
    }
}

.toggle-btn{
    position: absolute;
    
    right: 100px;
    .switch-btn{
        display: flex;
        label{
            font-weight: 900;
        }
    }
    form{
        margin-left: 25px;
        margin-top: 9px;
    }    
}

.confirm-header{
    text-align: center;
    margin-bottom: 10px;
}
.confirm-modal{
    .save-button{
        text-align: end;
    }
}

.upload-note{
    color: red;
    font-size: 14px;
  }
  
