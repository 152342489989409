.logo {
    width: 100%;
    height: auto;
    display: block; 
    margin: 0 auto;
    max-width: 100%; 
}

.nav-pills li a:hover {
    background-color: rgb(237, 126, 1);
}

.forms-container{
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.col-md-10 {
    padding-left: 0px;
    padding-right: 0px;
}

.ps-sidebar-root.css-1wvake5{
    color: white !important;
}

.ps-sidebar-container.css-dip3t8{
    background-color: black !important;
}

.bg-dark{
    background-color: #dddd !important;
}

.text-white{
    color: black !important;
}

.active_page{
    background-color: rgb(237, 126, 1);
}
