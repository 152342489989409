
.dashboard-content{
    height: 100%;
    position: fixed;
    width: 100%;
    .fess-logo{
        height: 10rem;
    }
    .user-info{
        height: 20rem;
        .logo{
            svg{
                margin-top: 1rem;
                font-size: 3rem;
            }
        }
        .username{
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}